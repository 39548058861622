import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const SecondPage = () => (
    <Layout>
        <SEO title="Easter egg found!" />
        <h1>Easter egg</h1>
        <p>
            This page is being worked on in my spare time. If you have
            questions, reach out to me at josemsantos1994@gmail.com.
        </p>
        <Link to="/">Go back to the homepage</Link>
    </Layout>
);

export default SecondPage;
